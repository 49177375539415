import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import Header from './sections/header/_hero'
import PresteAtencao from './sections/preste-atencao/_index'
import CreditoImobiliario from './sections/credito-imobiliario/_index'
import PessoaJuridica from './sections/pessoa-juridica/_index'

import pageContext from './pageContext.json'

const SegundaViaBoleto = () => {
  return (
    <Layout pageContext={pageContext}>
      <Header />
      <PresteAtencao />
      <CreditoImobiliario />
      <PessoaJuridica />
    </Layout>
  )
}

export default SegundaViaBoleto
