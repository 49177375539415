import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const List = styled.div`

  .steps {
    padding-bottom: 32px;

    > div {
      max-width: calc(100% - 56px);
    }
  }

  button {

    @media ${device.tablet} {
     max-width: 234px;
     height: 48px;
    }
  }
`

export const ButtonSteps = styled.div`
  width: 40px;
  height: 40px;
  background: ${orange.extra};
  border-radius: 50%;
  display: grid;
  margin-right: 16px;
`
