import React from 'react'
import SectionComponent from 'src/components/SectionComponent'

const PresteAtencao = () => {
  return (
    <SectionComponent
      id='preste-atencao'
      SectionStyles='bg-orange--extra'
      RowStyles='justify-content-lg-center'
      minHeight={{ sm: '302px', md: '192px', lg: '250px', xl: '206px' }}
    >
      <div className='col-12 col-md-3 col-xl-5'>
        <h2 className='text-white fs-24 lh-30 fs-lg-40 lh-lg-50 mt-2 mb-4 mb-md-0 font-sora'>Preste atenção</h2>
      </div>
      <div className='col-12 col-md-9 col-lg-6 col-xl-7'>
        <p className='text-white fs-14 lh-17 fs-lg-18 lh-lg-22 mb-0'>
          Para garantir sua segurança e evitar fraudes de terceiros no momento em que o boleto é gerado, por favor,
          <strong className='fs-18 lh-22'> verifique se os três primeiros números na linha digitável são 077</strong>.
          Se não forem estes números, descarte o boleto imediatamente e ligue para
          o setor de Cobrança do Banco Inter - 3003 4070 opção 4, que enviará o arquivo correto.
        </p>
      </div>
    </SectionComponent>
  )
}

export default PresteAtencao
