import React, { useState } from 'react'
import Img from 'gatsby-image'

import SectionComponent from 'src/components/SectionComponent'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import SegundaViaBoletoPJ from 'src/components/UI/Forms/SegundaViaBoletoPJ'

import usePageQuery from '../../pageQuery'
import stepsDataJson from '../../assets/data/passos-segunda-via-pessoa-juridica.json'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'
import { List, ButtonSteps } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IStepsData {
  step: string;
  stepTitle: string;
  description: string;
}

const PessoaJuridica = () => {
  const domReady = useDomReady()
  const data = usePageQuery()
  const windowWidth = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const stepsJson: IStepsData[] = stepsDataJson

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <SegundaViaBoletoPJ closeModal={() => setIsModalOpen(false)} />
    </Modal>
  )

  return (
    <>
      {cardsModal}
      <SectionComponent
        id='pessoa-juridica'
        SectionStyles='bg-white align-items-start align-items-lg-center'
        minHeight={{ sm: '1170px', md: '1264px', lg: '668px', xl: '747px', xxl: '780px' }}
      >
        <div className='col-12 col-md-10 col-lg-5 mb-5 mb-lg-0 mx-auto mx-xl-0'>
          {windowWidth < WIDTH_MD
            ? <Img
                fluid={data.pessoaJuridicaSegundaViaBoletoMobile.fluid}
                alt='Mulher sentada em um sofá, enquanto faz carinho em seu gatinho de estimação, tomando seu chá ao mesmo tempo com seu notebook no colo.'
              />
            : <Img
                fluid={data.pessoaJuridicaSegundaViaBoletoTabletDesktop.fluid}
                alt='Mulher sentada em um sofá, enquanto faz carinho em seu gatinho de estimação, tomando seu chá ao mesmo tempo com seu notebook no colo.'
              />
          }
        </div>
        <div className='col-12 col-lg-6 pr-xl-4'>
          <h2 className='fs-32 lh-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4 font-sora'>Pessoa jurídica</h2>
          <p className='fs-24 lh-30 text-grayscale--400 mb-lg-4 pb-lg-3 font-sora'>Como emitir a 2ª via do boleto?</p>
          <List className='pt-2 pt-xl-0'>
            {stepsJson.map((item: IStepsData) => (
              <div key={item.step} className='steps d-flex'>
                <ButtonSteps className='text-white align-items-center text-center fw-600'>
                  {item.step}
                </ButtonSteps>
                <div>
                  <span className='font-sora fs-20 lh-25 fw-600 text-grayscale--500 mb-2 d-block' dangerouslySetInnerHTML={{ __html: item.stepTitle }} />
                  <p className='fs-18 lh-22 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            ))}
            <button
              onClick={() => {
                setIsModalOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: 'Pessoa jurídica',
                  element_action: 'click button',
                  element_name: 'Boleto Pessoa Jurídica',
                })
              }}
              title='Acessar Boleto Pessoa Jurídica'
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none mt-4'
            >
              Boleto Pessoa Jurídica
            </button>
          </List>
        </div>
      </SectionComponent>
    </>
  )
}

export default PessoaJuridica
