import styled from 'styled-components'
import { orange, grayscale } from 'src/styles/colors'

export const GridContent = styled.div`

  .bread-crumb  {
    span {
      color: ${orange.extra} !important;
      font-weight: 700;
      opacity: 1;
    }
    a {
      color: ${grayscale[500]};
    }
  }
`
