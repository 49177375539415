import React from 'react'
import BreadCrumb from 'src/components/Breadcrumb'
import SectionComponent from 'src/components/SectionComponent'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import usePageQuery from '../../pageQuery'
import { GridContent } from './style'

const Hero = () => {
  const data = usePageQuery()
  const windowWidth = useWidth()

  return (
    <SectionComponent
      id='segunda-via-boleto'
      bgMobile='heroBannerMobile'
      bgTablet='heroBannerTablet'
      bgDesktopLG='heroBannerDesktopLG'
      bgDesktopXL='heroBannerDesktopXL'
      bgDesktopXXL='heroBannerDesktopXXL'
      SectionStyles='bg-white align-items-end align-items-md-center'
      pageDataImages={data}
      minHeight={{ sm: '620px', md: '534px', lg: '544px', xl: '544px', xxl: '609px' }}
      backgroundSize={`${windowWidth < WIDTH_MD ? 'contain' : 'initial' }`}
      backgroundPosition={`${windowWidth < WIDTH_MD ? 'top center' : 'center left' }`}
    >
      <GridContent className='col-12 col-md-5 offset-md-7'>
        <BreadCrumb
          sectionName='Emita 2ª via de boleto, sem burocracia'
          type='Home'
          text='2ª via do boleto'
          link='/segunda-via-boleto/'
        />
        <h1 className='text-grayscale--500 fs-32 lh-40 fs-lg-40 lh-lg-50 mt-2 mb-3 font-sora'>
          Emita 2ª via de boleto, sem burocracia
        </h1>
        <p className='text-grayscale--500 fs-18 lh-22'>
          Perdeu ou precisa acessar seu boleto de <strong>Crédito Imobiliário</strong> ou <strong>Capital de Giro</strong>? Retire aqui sua 2ª via de boleto com segurança e tranquilidade.
        </p>
      </GridContent>
    </SectionComponent>
  )
}

export default Hero
