import React from 'react'
import Img from 'gatsby-image'

import SectionComponent from 'src/components/SectionComponent'
import useWidth from 'src/hooks/window/useWidth'

import usePageQuery from '../../pageQuery'
import stepsDataJson from '../../assets/data/passos-segunda-via-imobiliario.json'

import { WIDTH_MD } from 'src/utils/breakpoints'
import { List, ButtonSteps } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IStepsData {
  step: string;
  stepTitle: string;
  description: string;
}

const CreditoImobiliario = () => {
  const windowWidth = useWidth()
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const stepsJson: IStepsData[] = stepsDataJson

  return (
    <SectionComponent
      id='credito-imobiliario'
      SectionStyles='bg-white align-items-start align-items-lg-center'
      RowStyles='justify-content-md-center justify-content-lg-between'
      minHeight={{ sm: '1280px', md: '1270px', lg: '703px', xl: '809px' }}
    >
      <div className='col-12 col-md-10 col-lg-5 order-lg-last mb-5 mb-lg-0'>
        {windowWidth < WIDTH_MD
          ? <Img
              fluid={data.creditoImobiliarioSegundaViaBoletoMobile.fluid}
              alt='Mulher sentada em um sofá, enquanto faz carinho em seu gatinho de estimação, tomando seu chá ao mesmo tempo com seu notebook no colo.'
            />
          : <Img
              fluid={data.creditoImobiliarioSegundaViaBoletoTabletDesktop.fluid}
              alt='Mulher sentada em um sofá, enquanto faz carinho em seu gatinho de estimação, tomando seu chá ao mesmo tempo com seu notebook no colo.'
            />
        }
      </div>
      <div className='col-12 col-lg-6'>
        <h2 className='fs-30 lh-38 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4 font-sora'>Crédito imobiliário</h2>
        <p className='fs-24 lh-30 text-grayscale--400 font-sora mb-lg-4 pb-lg-3 font-sora'>Como emitir a 2ª via do boleto?</p>
        <List className='pt-2 pt-xl-0'>
          {stepsJson.map((item: IStepsData) => (
            <div key={item.step} className='steps d-flex'>
              <ButtonSteps className='text-white align-items-center text-center fw-600'>
                {item.step}
              </ButtonSteps>
              <div>
                <span className='font-sora fs-20 lh-25 fw-600 text-grayscale--500 mb-2 d-block' dangerouslySetInnerHTML={{ __html: item.stepTitle }} />
                <p className='fs-18 lh-22 text-grayscale--400 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            </div>
          ))}
          <a
            href='https://emprestimo.bancointer.com.br/'
            target='_blank'
            rel='noreferrer'
            className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
            title='Emitir 2ª via do boleto'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_03',
                section_name: 'Crédito imobiliário',
                element_action: 'click button',
                element_name: 'Emitir 2ª via do boleto',
                redirect_url: 'https://emprestimo.bancointer.com.br/',
              })
            }}
          >
            Emitir 2ª via do boleto
          </a>
        </List>
      </div>
    </SectionComponent>
  )
}

export default CreditoImobiliario
