import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      heroBannerMobile: imageSharp(fluid: {originalName: { regex: "/hero-banner-mobile-segunda-via-boleto/" }}) {
        fluid(maxWidth: 355, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroBannerTablet: imageSharp(fluid: {originalName: { regex: "/hero-banner-tablet-segunda-via-boleto/" }}) {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroBannerDesktopLG: imageSharp(fluid: {originalName: { regex: "/hero-banner-desktop-lg-segunda-via-boleto/" }}) {
        fluid(maxWidth: 549, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroBannerDesktopXL: imageSharp(fluid: {originalName: { regex: "/hero-banner-desktop-xl-segunda-via-boleto/" }}) {
        fluid(maxWidth: 747, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      heroBannerDesktopXXL: imageSharp(fluid: {originalName: { regex: "/hero-banner-desktop-xxl-segunda-via-boleto/" }}) {
        fluid(maxWidth: 964, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      creditoImobiliarioSegundaViaBoletoMobile: imageSharp(fluid: {originalName: { regex: "/credito-imobiliario-segunda-via-boleto-mobile/" }}) {
        fluid(maxWidth: 616, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      creditoImobiliarioSegundaViaBoletoTabletDesktop: imageSharp(fluid: {originalName: { regex: "/credito-imobiliario-segunda-via-boleto-tablet-desktop/" }}) {
        fluid(maxWidth: 587, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      pessoaJuridicaSegundaViaBoletoMobile: imageSharp(fluid: {originalName: { regex: "/pessoa-juridica-segunda-via-boleto-mobile/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      pessoaJuridicaSegundaViaBoletoTabletDesktop: imageSharp(fluid: {originalName: { regex: "/pessoa-juridica-segunda-via-boleto-tablet-desktop/" }}) {
        fluid(maxWidth: 587, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
